import { React, useRef,useState,useEffect } from "react";
import CardHeader from "./CardHeader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { InView } from "react-intersection-observer";
import "react-circular-progressbar/dist/styles.css";
import { throttle } from '../../const/CustomThrottle';


export default function Commission() {
  const[inView, setInView] = useState(false);
  const [hasBeenInView, setHasBeenInView] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const scrollRef = useRef(null);
  const lakhsNumber = [10, 5, 0];
  let numbers = Array.from({length: 49}, (_, index) => (index*10000).toLocaleString("en-IN"));




  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(prevPercentage => {
        if (prevPercentage < 13) {
          return prevPercentage + 1;
        }
        clearInterval(interval);
        return 12;
      });
    }, 100); 

    return () => {
      clearInterval(interval);
    };
  }, [inView]); 


  const handleView = throttle((inView) => {
    if (inView && !hasBeenInView) {
        setInView(true);
        setHasBeenInView(true);
    }
  }, 2000);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    // Scroll both containers to the bottom gradually
    const scrollHeight =
      scrollContainer.scrollHeight - scrollContainer.clientHeight;
    const scrollDuration = 300;
    const interval =40;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += interval;

      const ease = (currentTime / scrollDuration) ** 2;
      scrollContainer.scrollTop = InView
        ? ease * scrollHeight
        : scrollHeight - ease * scrollHeight;

      if (currentTime < scrollDuration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  },[inView]);

  return (
    <>
        <InView onChange={handleView}>
            <div className="card_name  w-[286px] h-full pt-[14px]   rounded-[4px] bg-[#fff] ">
                <CardHeader name={"Commission Panel"} />

                <div className="commission_graph relative ml-[16px] mr-[16px] w-[254px] pb-[13px] rounded-[8px] bg-[#FFE9A1]">
                <div className="sub-header-commission w-max ml-[6px] mt-[12px] flex">
                    <h1 className="htext text-center w-[24px] text-[20px] font-semibold">
                    ₹
                    </h1>
                    <div
                    ref={scrollRef}
                    className={`number_scroll_commission htext text-[20px] font-semibold h-[25px] overflow-hidden`}
                    >
                    {numbers.map((number) => (
                        <li key={number}>{number}.00</li>
                    ))}
                    </div>
                </div>
                <div className="small_heading">
                    <h1 className="ml-[16px] mb-[36px] text-[10px] font-semibold   text-[#00000066]">
                    Overall Commission
                    </h1>
                </div>

                <div className="horizontal_lines relative flex flex-col  text-[#000000B2] gap-[27px] ml-[8px]">
                    {lakhsNumber.map((ele, index) => {
                    return (
                        <div key={index} className="line-1 flex gap-1 items-center">
                        <span className="ruppes text-[6px] font-semibold shrink-0">
                            {ele} Lakhs
                        </span>
                        <div
                            className={`${
                            index === 0 ? "w-full" : "w-[217.3px]"
                            } h-[0.8px] bg-[#0000004D]  ml-auto`}
                        />
                        </div>
                    );
                    })}

                    {/* cross_line  */}
                    <div className="cross_line  absolute top-[35px] left-[26px]">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="139"
                        height="41"
                        viewBox="0 0 140 43"
                        fill="none"
                    >
                        <path
                        d="M1.1549 42C-1.23898 28.3333 23.1786 1 140 1"
                        stroke="black"
                        />
                    </svg>
                    <svg
                        className="absolute top-0 right-[55px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                        fill="none"
                    >
                        <circle cx="2.5" cy="2.5" r="2" fill="#FFEEFE" stroke="black" />
                    </svg>
                    </div>

                    {/* big black dot  */}
                    <div className="black-dot absolute top-[33px] right-[74px]">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                        fill="none"
                    >
                        <circle cx="2.5" cy="2.5" r="2.5" fill="#010101" />
                    </svg>
                    </div>

                    {/* multiple dotted lines  */}
                    <div className="dotted-lines absolute top-[34px] right-[9px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="3" viewBox="0 0 63 3" fill="none">
                            <path d="M1 1.9723C19.0855 2.19518 55.0851 1 63 1" stroke="black" strokeDasharray="2 2"/>
                        </svg>
                    </div>
                </div>  

                <div className="duration htext text-[6px] mt-[8px] ml-[48px] flex gap-[26px] font-semibold">
                    <span>1D</span>
                    <span>1W</span>
                    <span>1M</span>
                    <span>3M</span>
                    <span>6M</span>
                    <span>1Y</span>
                </div>
                </div>

                <div className="commission-footer flex mt-[10px] ml-[16px] gap-[10px]">
                <div className="commission-percentage relative w-[67px] h-[67px] flex items-center justify-center rounded-[8px] bg-[#0F0F0F]">
                    <div  className="progressbar-container " style={{ width: "60%", height: "60%" }}>
                        <CircularProgressbar
                            styles={buildStyles({
                            textSize: "30px",
                            textColor: "#fff",
                            pathColor: `rgba(246, 186, 0, 1`,
                            })}
                            value={percentage}
                        />
                        <div className="animated-text">{`${percentage < 10 ? "0" : ""}${percentage}%`}</div>
                    </div>
                </div>

                <div className="commission-monthly w-[177px] h-[67px] bg-[#FFEDB7] rounded-[8px]">
                    <div className="text-[20px] htext flex font-semibold ml-[14px] mt-[16px] mb-[2px]">
                    <h1 className="htext w-[24px] text-center h-auto text-[20px] font-semibold">
                        ₹
                    </h1>
                    <span className="htext text-[20px] font-semibold">
                        40,000.00
                    </span>
                    
                    </div>

                    <p className="ml-[21px] text-[8px]">Monthly Commission</p>
                </div>
                </div>
            </div>
      </InView>
    </>
  );
}
