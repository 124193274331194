import { React, useState } from "react";
import { InView } from "react-intersection-observer";
import { throttle } from "../../const/CustomThrottle";

export default function MeetingGraph() {
  const [inView, setInView] = useState(false);
  const [hasBeenInView, setHasBeenInView] = useState(false);

  const handleView = throttle((inView) => {
    if (inView && !hasBeenInView) {
      setInView(true);
      setHasBeenInView(true);
    }
  }, 100);
  return (
    <>
      <InView onChange={handleView}>
        <div className="meeting_graph_container m-4 w-[358px] h-[275px] rounded-[4px] bg-[#fff] border-[.4px] border-solid border-[rgba(0, 0, 0, 0.2)]">
          <div className="meeting_graph_header mt-[10px] ml-[12px] mb-[25.7px]">
            <h1 className="text-[#000000CCtext-[14px] font-semibold ">
              No.of Meetings
            </h1>
          </div>

          {/* chart  */}
          <div className="chart flex">
            {/* chart days */}
            <div className="chart_days flex flex-col-reverse h-max self-end text=[#000] gap-[8px] font-semibold text-[8px] ml-[21px] pb-[20px] ">
              <span className="day">10</span>
              <span className="day">20</span>
              <span className="day">30</span>
              <span className="day">40</span>
              <span className="day">50</span>
              <span className="day">60</span>
              <span className="day">70</span>
            </div>

            {/* chart Lines  */}
            <div className="inside_chart relative">
              {/* <img src={Bg_lines} alt="" /> */}
              <svg
                width="298"
                height="172"
                viewBox="0 0 298 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="4.90657"
                  y1="0.168945"
                  x2="4.90656"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="4.90657"
                  y1="0.168945"
                  x2="4.90656"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="28.9385"
                  y1="0.168945"
                  x2="28.9385"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="28.9385"
                  y1="0.168945"
                  x2="28.9385"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="52.971"
                  y1="0.168945"
                  x2="52.971"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="3.37429"
                  x2="-1.26549e-08"
                  y2="3.37427"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="52.971"
                  y1="0.168945"
                  x2="52.971"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="77.0035"
                  y1="0.168945"
                  x2="77.0035"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="24.0766"
                  x2="-1.26549e-08"
                  y2="24.0765"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="77.0035"
                  y1="0.168945"
                  x2="77.0035"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="24.0766"
                  x2="-1.26549e-08"
                  y2="24.0765"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="101.035"
                  y1="0.168945"
                  x2="101.035"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="44.7798"
                  x2="-1.26549e-08"
                  y2="44.7798"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="101.035"
                  y1="0.168945"
                  x2="101.035"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="44.7798"
                  x2="-1.26549e-08"
                  y2="44.7798"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="125.067"
                  y1="0.168945"
                  x2="125.067"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="65.481"
                  x2="-1.26549e-08"
                  y2="65.481"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="125.067"
                  y1="0.168945"
                  x2="125.067"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="65.481"
                  x2="-1.26549e-08"
                  y2="65.481"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="149.099"
                  y1="0.168945"
                  x2="149.099"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="86.184"
                  x2="-1.26549e-08"
                  y2="86.184"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="149.099"
                  y1="0.168945"
                  x2="149.099"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="86.184"
                  x2="-1.26549e-08"
                  y2="86.184"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="173.132"
                  y1="0.168945"
                  x2="173.132"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="106.887"
                  x2="-1.26549e-08"
                  y2="106.887"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="173.132"
                  y1="0.168945"
                  x2="173.132"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="106.887"
                  x2="-1.26549e-08"
                  y2="106.887"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="197.164"
                  y1="0.168945"
                  x2="197.164"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="127.588"
                  x2="-1.26549e-08"
                  y2="127.588"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="197.164"
                  y1="0.168945"
                  x2="197.164"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="127.588"
                  x2="-1.26549e-08"
                  y2="127.588"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="221.196"
                  y1="0.168945"
                  x2="221.196"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="148.292"
                  x2="-1.26549e-08"
                  y2="148.292"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="245.229"
                  y1="0.168945"
                  x2="245.229"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="298"
                  y1="168.995"
                  x2="-1.26549e-08"
                  y2="168.995"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="269.261"
                  y1="0.168945"
                  x2="269.261"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
                <line
                  x1="293.293"
                  y1="0.168945"
                  x2="293.293"
                  y2="172"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.2"
                />
              </svg>

              <div className="absolute flex gap-[20px] flex-wrap-reverse bottom-[2px] left-[8px]">
                <div
                  style={{ height: `${inView ? "62px" : "3px"}` }}
                  className="bar bg-[#000]  w-[31px] h-[40px] rounded-[2px] transition-height duration-500"
                ></div>
                <div
                  style={{ height: `${inView ? "52px" : "3px"}` }}
                  className="bar bg-[#000] w-[31px] h-[4px] rounded-[2px] transition-height duration-500"
                ></div>
                <div
                  style={{ height: `${inView ? "80px" : "3px"}` }}
                  className="bar   bg-[#FEE7A1] w-[31px] h-[4px] rounded-[2px] transition-height duration-500"
                ></div>
                <div
                  style={{ height: `${inView ? "38px" : "3px"}` }}
                  className="bar bg-[#000] w-[31px] h-[4px] rounded-[2px] transition-height duration-500"
                ></div>
                <div
                  style={{ height: `${inView ? "13px" : "3px"}` }}
                  className="bar bg-[#000] w-[31px] h-[4px] rounded-[2px] transition-height duration-500"
                ></div>
                <div
                  style={{ height: `${inView ? "1px" : "3px"}` }}
                  className="bar bg-[#000] w-[31px] h-[4px] rounded-[2px] transition-height duration-500"
                ></div>

                <div className="box_over absolute flex justify-center top-[-25px] left-[115px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="25"
                    viewBox="0 0 50 25"
                    fill="none"
                  >
                    <path
                      d="M0 1C0 0.447715 0.447715 0 1 0H49C49.5523 0 50 0.447715 50 1V20.7391C50 21.2914 49.5523 21.7391 49 21.7391H9.23084C8.89648 21.7391 8.58425 21.9062 8.39879 22.1844L6.93776 24.376C6.73985 24.6728 6.30362 24.6728 6.10571 24.376L4.64469 22.1844C4.45923 21.9062 4.147 21.7391 3.81264 21.7391H1C0.447716 21.7391 0 21.2914 0 20.7391V1Z"
                      fill="#FEE7A1"
                    />
                  </svg>

                  <h1 className="htext absolute top-0 text-[14px] font-semibold">
                    38
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* chart bottom months  */}
          <div className="chart_months htext flex mb-[6px] text-[8px] ml-[36px] gap-[17px] font-semibold">
            <span className="month">JAN-FEB</span>
            <span className="month">MAR-APR</span>
            <span className="month">MAY-JUN</span>
            <span className="month">JUL-AUG</span>
            <span className="month">SEP-OCT</span>
            <span className="month">NOV-DEC</span>
          </div>

          {/* seperate line  */}
          <div className="w-[34px] h-[2px] ml-[140px] bg-[#FEE7A1] align-middle"></div>
          <hr className="meetingSeperator w-[318px] h-[.5px] bg-[rgba(0, 0, 0, 0.30)] mx-[auto] mb-[12px]" />
        </div>
      </InView>
    </>
  );
}
