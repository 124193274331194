import React from "react";
import RpCard from "./RpCard";
import Commission from "./Commission";
import Closure from "./Closure";

export default function Carousel() {
  return (
    <>
      <div className="card_carousel pl-4 w-full  flex gap-[15px] overflow-x-auto pb-[20px]">
        <RpCard />
        <Commission />
        <Closure />
      </div>
    </>
  );
}